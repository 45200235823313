<template>
    <styled-slideout
        :value="value"
        @input="$emit('input', $event)">
        <div class="pa-5">
            <h2>Ad Preview Email</h2>
            <p class="mb-4">
                Use the example messaging below to share secure ad preview
                url with  your customer
            </p>

            <div>
                <copy-text-area
                    hide-download
                    class="mb-4">
                    <p>
                        Hello,
                    </p>
                    <p>
                        Your ads are now available for review.
                    </p>
                    <p>
                        Please use the secure link below to view them:
                        {{ previewSecureUrl }}.
                    </p>
                    <p>
                        Within that tool you will be able to browse all available ads and, in cases where a platform
                        has multiple placements, see how those ads look within the context of each placement.
                    </p>
                    <p>
                        Please note that the actual ad display may vary from what you're seeing but the tool will provide
                        context around the most common formats users will see on each platform when they see your ads.
                    </p>
                    <p>
                        Thank you,<br>
                        {{ dealer.agency.name }} Team
                    </p>
                </copy-text-area>
            </div>
        </div>
    </styled-slideout>
</template>

<script>
import StyledSlideout from '@/components/globals/StyledSlideout';
import CopyTextArea from '@/components/globals/CopyTextArea';

export default {
    name: 'AdPreviewEmailSlideout',
    components: {
        StyledSlideout,
        CopyTextArea,
    },
    props: {
        dealer: {
            type: Object,
            required: true
        },
        previewSecureUrl: {
            type: String,
            required: true
        },
        value: {
            type: Boolean,
            default: false
        }
    },
};
</script>

<style lang="scss" scoped>
.contact-panel {
    padding: 30px;
    background-color: rgba($gray, 0.05);
}
</style>