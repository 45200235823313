<template>
    <styled-interface
        no-padding>
        <template
            v-if="!dealerLoading"
            #interface-toolbar-external>
            <management-toolbar />
        </template>
        <div
            v-if="!dealerLoading"
            id="playbook-management"
            :class="{
                'no-ad-deployment': !userHasAdDeploymentAccess
            }">
            <div class="main">
                <div class="top with-padding">
                    <div
                        class="top__bar">
                        <h2>Campaign Management</h2>
                        <div class="selected-wrapper flexed">
                            <action-button
                                icon="refresh"
                                size="17"
                                :loading="screenUpdating"
                                @click="reset()">
                                Reset
                            </action-button>

                            <styled-tooltip
                                v-if="!hasSelections"
                                :max-width="250"
                                position="top">
                                <styled-button
                                    class="selected-button"
                                    disabled>
                                    VIEW PREVIEWS
                                </styled-button>
                                <template #content>
                                    <span>Please select at least 1 Ad via the Ads tab to generate Ad Previews.</span>
                                </template>
                            </styled-tooltip>
                            <styled-button
                                v-else
                                class="selected-button"
                                @click="handleViewPreviews()">
                                VIEW PREVIEWS
                            </styled-button>
                            <div
                                class="create-button"
                                @click.stop="showDropdown">
                                <div class="create-circle">
                                    <div class="create-plus">
                                        +
                                    </div>
                                </div>
                                CREATE NEW
                                <create-new-dropdown
                                    v-if="dropdownActive"
                                    v-outside-click="hideDropdown" />
                            </div>
                        </div>

                        <div
                            id="custom-tabs"
                            class="tabs-wrapper">
                            <v-tabs v-model="currentTab">
                                <v-tab
                                    v-for="([tab, { label }]) in Object.entries(tabs)"
                                    :key="label"
                                    :href="'#'+tab"
                                    :class="{ deployed: tab === 'deployed-ads' }"
                                    @click="currentTab = tab">
                                    {{ label }}
                                    <div v-if="objectTypeDetails[tab]">
                                        <div v-if="objectTypeDetails[tab].count">
                                            <span class="selected-btn">
                                                {{ objectTypeDetails[tab].count }} Selected
                                                <action-button
                                                    class="close"
                                                    width="10"
                                                    height="10"
                                                    icon="close"
                                                    color="white"
                                                    @click="removeSelection(tab)" />
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="!parentTabs.includes(tab) && tab != 'deployed-ads'"
                                        class="pl-2">
                                        <div
                                            v-if="objectTypeDetails[TAB_CAMPAIGN].count && !objectTypeDetails[TAB_ADSET].count">
                                            for {{ pluralize(objectTypeDetails[TAB_CAMPAIGN].count, 'Campaign') }}
                                        </div>
                                        <div v-if="objectTypeDetails[TAB_ADSET].count">
                                            for {{ pluralize(objectTypeDetails[TAB_ADSET].count, 'Set') }}
                                        </div>
                                    </div>
                                </v-tab>
                                <v-tab-item
                                    :value="currentTab">
                                    <div
                                        v-if="!selectedPlatforms.length"
                                        class="pa-5">
                                        Please select at least one platform to use this tool!
                                    </div>
                                    <keep-alive
                                        v-else
                                        :max="10">
                                        <component
                                            :is="tabs[currentTab].component"
                                            :key="tabKeys[currentTab]"
                                            :platforms="sortedSelectedPlatforms" />
                                    </keep-alive>
                                </v-tab-item>
                            </v-tabs>
                        </div>
                    </div>
                </div>

                <styled-dialog v-model="viewPreviewDialog">
                    <div class="view-preview-dialog">
                        <div v-if="sharingAdIdsLoadingStatus">
                            <styled-loader />
                        </div>
                        <div v-else>
                            <action-button
                                class="modal-close"
                                icon="close"
                                color="blue"
                                size="10"
                                position="top"
                                @click="viewPreviewDialog = false" />

                            <h2>Secure Preview URL</h2>
                            <p>
                                We have generated a secure preview URL of the ads you selected. You can copy it to your
                                clipboard or use our pre-defined template to share it via email.
                            </p>
                            <div class="layout mt-4">
                                <v-text-field
                                    v-model="previewSecureUrl"
                                    label="PREVIEW URL"
                                    placeholder="Preview URL"
                                    class="styled-field flex preview-url"
                                    disabled />

                                <div class="action-buttons flex layout">
                                    <action-button
                                        v-clipboard:copy="previewSecureUrl"
                                        v-clipboard:success="onCopy"
                                        icon="copy"
                                        size="18"
                                        class="flex">
                                        COPY
                                    </action-button>
                                    <action-button
                                        icon="mail"
                                        size="18"
                                        class="flex"
                                        @click="adPreviewEmailSlideoutActive = true">
                                        EMAIL
                                    </action-button>
                                    <action-button
                                        :href="previewSecureUrl"
                                        target="_blank"
                                        icon="browser"
                                        size="18"
                                        class="flex"
                                        @click="viewPreviewDialog = false">
                                        VIEW
                                    </action-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </styled-dialog>
            </div>
        </div>

        <pinterest-dynamic-ads-modal
            :platforms="selectedPlatforms" />
        <ad-preview-email-slideout
            v-model="adPreviewEmailSlideoutActive"
            :dealer="currentDealer"
            :preview-secure-url="previewSecureUrl" />
    </styled-interface>
</template>

<script>
import CreateNewDropdown from '@/components/globals/CreateNewDropdown';
import StyledLoader from '@/components/globals/StyledLoader';
import StyledInterface from '@/components/globals/StyledInterface';
import ManagementToolbar from '@/components/playbook-management/ManagementToolbar';
import AdPreviewEmailSlideout from './AdPreviewEmailSlideout';
import ActionButton from '@/components/globals/ActionButton';
import StyledButton from '@/components/globals/StyledButton';
import StyledTooltip from '@/components/globals/StyledTooltip';
import StyledDialog from '@/components/globals/StyledDialog';
import pluralize from '@/helpers/pluralize';
import { mapState, mapGetters, mapActions } from 'vuex';
import { TAB_AD, TAB_ADSET, TAB_CAMPAIGN, TAB_DEPLOYED_ADS } from './store';
import { SET_PM_CURRENT_TAB } from '@/store/mutation-types';
import PinterestDynamicAdsModal from '@/components/playbook-management/Ads/PinterestDynamicAdsModal';
import { PLATFORM_FACEBOOK } from '@/helpers/globals';
import { SUPPORTED_PLATFORMS } from '@/components/playbook-management/helpers/storeHelpers';

export default {
    name: 'PlaybookManagement',
    title: 'Campaign Management',
    components: {
        CreateNewDropdown,
        StyledLoader,
        StyledInterface,
        ManagementToolbar,
        AdPreviewEmailSlideout,
        ActionButton,
        StyledButton,
        StyledTooltip,
        StyledDialog,
        PinterestDynamicAdsModal
    },
    data() {
        return {
            dropdownActive: false,
            screenUpdating: false,
            active: true,
            viewPreviewDialog: false,
            sharePreviewFormat: {},
            previewSecureUrl: '',
            previewSecureUrlError: '',
            sharingAdIdsLoadingStatus: false,
            adPreviewEmailSlideoutActive: false,
            TAB_AD,
            TAB_ADSET,
            TAB_CAMPAIGN
        };
    },
    computed: {
        ...mapState({
            dealerLoading: (state) => state.dealer.dealerLoading,
            currentDealer: (state) => state.dealer.currentDealer,
            selectedPlatforms: (state) => state.playbookManagement.selectedPlatforms
        }),
        ...mapGetters([
            'dealerPlatforms',
            'userHasAdDeploymentAccess'
        ]),
        ...mapGetters('playbookManagement', [
            'activePlatforms',
            'objectTypeDetails',
            'tabKeys'
        ]),
        tabs() {
            const defaultTabs = {
                [TAB_CAMPAIGN]: {
                    label: 'Campaigns',
                    component: () => import('./Campaigns')
                },
                [TAB_ADSET]: {
                    label: 'Sets',
                    component: () => import('./AdSets')
                },
                [TAB_AD]: {
                    label: 'Ads',
                    component: () => import('./Ads')
                }
            };

            const additionalTabs = {};

            if (this.userHasAdDeploymentAccess) {
                additionalTabs[TAB_DEPLOYED_ADS] = {
                    label: 'Deployed Ads',
                    component: () => import('./DeployedAds')
                };
            }

            return {
                ...additionalTabs,
                ...defaultTabs
            };
        },
        /**
         * Filter out the active platforms to the selected ones
         * to ensure they're in the correct order
         */
        sortedSelectedPlatforms() {
            return SUPPORTED_PLATFORMS.filter(platform =>
                this.selectedPlatforms.includes(platform) && this.activePlatforms.includes(platform)
            );
        },
        currentTab: {
            get() {
                return this.$store.state.playbookManagement.currentTab;
            },
            set(value) {
                this.$store.commit(SET_PM_CURRENT_TAB, value);
            }
        },
        parentTabs() {
            const tabs = Object.keys(this.tabs);
            return tabs.slice(0, tabs.indexOf(this.currentTab));
        },
        hasSelections() {
            return Object.values(this.objectTypeDetails).some(({ count }) => count > 0);
        }
    },
    async created() {
        await this.waitForDealerLoaded();
        this.reset();

        this.handleParamInitialization();
    },
    methods: {
        ...mapActions([
            'waitForDealerLoaded'
        ]),
        ...mapActions('playbookManagement', [
            'unselectAll',
            'removeChildren',
            'getPreviewUrl',
            'reset',
            'select'
        ]),
        handleViewPreviews() {
            this.viewPreviewDialog = true;
            this.generateAdsPreviewUrl();

        },
        async generateAdsPreviewUrl() {
            try {
                this.previewSecureUrlError = '';
                this.sharingAdIdsLoadingStatus = true;

                this.previewSecureUrl = await this.getPreviewUrl();
            } catch (error) {
                this.previewSecureUrlError = 'Error occurred while generating Ads Preview URL. Please try again for contact support if the problem continues.';
                console.error(error);
            } finally {
                this.sharingAdIdsLoadingStatus = false;
            }
        },
        onCopy() {
            this.$flash('Preview URL copied to clipboard!', 'green');
        },
        showDropdown() {
            this.dropdownActive = true;
        },
        hideDropdown() {
            this.dropdownActive = false;
        },
        removeSelection(tab) {

            if (tab !== this.currentTab) {
                this.currentTab = tab;
            }

            this.unselectAll({
                type: tab
            });

            this.removeChildren(tab);
        },
        /**
         * Right now we're using this method to redirect users from the
         * Communication Central, it's hardcoded to only work for Facebook
         * and we are assuming the object exists and is active since it has
         * triggered an alarm. In case we want this to be generic, we would also
         * need to support multiple platforms and maybe update the params whenever the
         * user makes a selection.
         */
        handleParamInitialization() {
            const { selected_tab, object_id } = this.$route.query;

            if (this.tabs[selected_tab]) {
                this.currentTab = selected_tab;
            }

            if (this.tabs[selected_tab] && object_id) {
                this.select({
                    platform: PLATFORM_FACEBOOK,
                    type: selected_tab,
                    objects: [object_id]
                });
            }
        },
        pluralize
    }
};
</script>

<style lang="scss" scoped>
.loading-container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-button {
    display: inline-block;
    margin-left: 30px;
    position: relative;
    color: white;
    padding: 12px 40px 12px 55px;
    border-radius: 50px;
    background: #7FD41B;
    font-weight: 600;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        width: 11px;
        height: 11px;
        background: white;
        right: 18px;
        top: 14px;
        transform: rotate(45deg);
        z-index: 1;
    }

    &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 15px;
        background: #7FD41B;
        right: 13px;
        top: 5px;
        z-index: 2;
    }
}

.create-circle {
    position: absolute;
    left: 0;
    width: 47px;
    height: 47px;
    background: white;
    border-radius: 50%;
    display: flex;
    color: #7FD41B;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    font-size: 45px;
    top: -1px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
}

.create-plus {
    background-color: #7FD41B;
    color: transparent;
    text-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}
</style>

<style lang="scss">
@import "@/assets/scss/playbook-management.scss";
</style>
