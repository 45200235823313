<template>
    <interface-toolbar>
        <template #section-date-range>
            <date-range-picker-presets />
        </template>
        <template #section-channels>
            <checkboxes-field
                v-model="selections"
                :options="options"
                label="Channel(s)" />
        </template>
        <!-- @todo introduce budgeting from Anton's original PR -->
    </interface-toolbar>
</template>

<script>
import InterfaceToolbar from '@/components/globals/InterfaceToolbar';
import DateRangePickerPresets from '@/components/globals/DateRangePickerPresets';
import CheckboxesField from '@/components/globals/fields/CheckboxesField';
import { mapGetters } from 'vuex';
import { SET_PM_SELECTED_PLATFORMS } from '@/store/mutation-types';
import debounce from 'debounce';

export default {
    components: {
        InterfaceToolbar,
        DateRangePickerPresets,
        CheckboxesField
    },
    data() {
        return {
            selections: []
        };
    },
    computed: {
        selectedPlatforms: {
            get() {
                return this.$store.state.playbookManagement.selectedPlatforms;
            },
            set(value) {
                this.$store.commit(SET_PM_SELECTED_PLATFORMS, value);
            }
        },
        ...mapGetters([
           'dealerPlatforms',
        ]),
        ...mapGetters('playbookManagement', [
           'activePlatforms',
        ]),
        options() {
            return this.dealerPlatforms
                .filter(platform => (
                    this.activePlatforms.includes(platform.name)
                ))
                .map(platform => ({
                    text: platform.display_name,
                    value: platform.name
                }));
        }
    },
    watch: {
        selections: debounce(function(value) {
            this.selectedPlatforms = value;
        }, 400)
    },
    async created() {
        await this.$store.dispatch('waitForDealerLoaded');
        this.selections = this.activePlatforms;
    }
};
</script>
