<template>
    <styled-dialog
        :value="shouldShowDynamicAdsModal()"
        :width="600">
        <template #heading>
            <h2>Dynamic Product Ads Notice</h2>
        </template>

        <p class="mb-5">
            Pinterest does not allow platforms like BuyerBridge to view dynamic ads outside of their ads manager.
            You can view deployed dynamic sets on the sets tab or use the menu on the Pinterest
            channel row to open the dynamic ads directly within Pinterest's ads manager.
        </p>

        <div class="text-xs-center mb-4">
            <styled-button @click="onClick">
                OKAY, CONTINUE
            </styled-button>
        </div>
        <div class="checkbox-container">
            <styled-checkbox
                label="Don't show this message again"
                :checked.sync="shouldDisableDynamicAdsModal"
                :square="false"
                :inline="true" />
        </div>

        <a
            href="#"
            class="red--text"
            @click.prevent="hideModal = true">
            cancel
        </a>
    </styled-dialog>
</template>

<script>
import StyledDialog from '@/components/globals/StyledDialog';
import StyledButton from '@/components/globals/StyledButton';
import StyledCheckbox from '@/components/globals/StyledCheckbox';
import { PLATFORM_PINTEREST } from '@/helpers/globals';
import { mapActions, mapState } from 'vuex';

export default {
    name: 'PinterestDynamicAdsModal',
    components: {
        StyledDialog,
        StyledButton,
        StyledCheckbox
    },
    props: {
        platforms: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            shouldDisableDynamicAdsModal: false,
            hideModal: false,
            teste: true
        };
    },
    computed: {
        ...mapState({
            selectedAdSets: (state) => state.playbookManagement.selectedAdSets,
            dismissedAlerts: (state) => state.settings.dismissedAlerts
        }),

        currentTab() {
            return this.$store.state.playbookManagement.currentTab;
        },
    },
    methods: {
        ...mapActions([
            'dismissAlert'
        ]),
        onClick() {
            if (this.shouldDisableDynamicAdsModal) {
                this.dismissAlert('dynamicAdsModal');
                return;
            }

            this.hideModal = true;
        },
        shouldShowDynamicAdsModal() {
            if (this.dismissedAlerts.includes('dynamicAdsModal') || this.hideModal) {
                return false;
            }

            return this.platforms.includes(PLATFORM_PINTEREST)
                && !this.selectedAdSets[PLATFORM_PINTEREST].length
                && this.currentTab === 'ad';
        },
    }
};
</script>

<style lang="scss">
.checkbox-container {
    justify-content: center;
    display: flex;
}
</style>